body {
  margin: 0;
  padding: 0;
  background: #fff;
  font-family: PPMoriRegular;
}
a {
  text-decoration: none !important;
}
.MuiAutocomplete-popper {
  z-index: 99999999 !important;
}

@font-face {
  font-family: "PPMoriRegular";
  src: url("./fonts/PPMoriRegular.otf");
  format: ("otf");
}

@font-face {
  font-family: "PPMoriSemiBold";
  src: url("./fonts/PPMoriSemiBold.otf");
  format: ("otf");
}
